<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-header">
          <el-col>
            <el-button type="primary"
                       size="medium"
                       v-if="userPermissions.indexOf('package_create') != -1 "
                       @click="addButton(0)">添加
            </el-button>
          </el-col>
        </el-row>

        <el-row class="table-search">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="套餐名称">
              <el-input placeholder="请输入套餐名称" clearable v-model="searchForm.name"/>
            </el-form-item>

            <el-form-item label="状态">
              <el-select v-model="searchForm.status" placeholder="请选择" clearable>
                <el-option v-for="item in typeList"
                           :key="item.id"
                           :label="item.text"
                           :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ApeTable ref="apeTable" :data="questionBankList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
              slot="first-column"
              width="80"
              align="center"
              label="Drag">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column
              label="状态"
              width="100"
          >
            <template slot-scope="scope">
              <span :class=" scope.row.status == -1 ? 'color-red':'color-green' ">{{ scope.row.status_alias }}</span>
            </template>
          </el-table-column>

          <el-table-column
              v-if="buttonType=='icon'"
              label="操作"
              width="120"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('package_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='text'"
              label="操作"
              width="120"
              fixed="right">
            <template slot-scope="scope">
              <span>
                <el-button size="mini" v-if="userPermissions.indexOf('package_edit') != -1"
                           @click="editButton(scope.row.id)">编辑</el-button>
                <el-popover
                    v-if="userPermissions.indexOf('package_delete') != -1"
                    placement="top"
                    width="150"
                    v-model="scope.row.visible">
                  <p>确定要删除记录吗？</p>
                  <div style="text-align: right; margin: 0;">
                    <el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>
                    <el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>
                  </div>
                  <el-button slot="reference" type="danger" size="mini">删除</el-button>
                </el-popover>
              </span>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-form :model="formData" :rules="rules" ref="questionBankForm" label-position="right"
                 label-width="96px">

          <el-form-item label="套餐名称" prop="name">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>

          <el-form-item label="持续时间" prop="duration">
            <el-input v-model="formData.duration" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="日期类型">
            <el-radio-group v-model="dataType" @change="radioChange">
              <el-radio :label="3" border="">年</el-radio>
              <el-radio :label="2" border="">月</el-radio>
              <el-radio :label="1" border="">日</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="原价(元)" prop="price">
            <el-input v-model="formData.price" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>

          <el-form-item label="优惠(元)" prop="reduction_price">
            <el-input v-model="formData.reduction_price"
                      onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>

          <el-form-item label="实付价格(元)" prop="actual_price">
            <el-input v-model="formData.actual_price" disabled></el-input>
          </el-form-item>

          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="formData.status">
              <el-radio label="0" border>启用</el-radio>
              <el-radio label="-1" border>禁用</el-radio>
            </el-radio-group>
          </el-form-item>

        </el-form>
      </template>
    </ApeDrawer>
  </div>
</template>

<script>

const DEL = "del";
const DATE_CODE = 1;
const MOUNTH_CODE = 2;
const YEAR_CODE = 3;
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import Sortable from 'sortablejs'
import {mapGetters} from 'vuex'

export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
  },
  data() {
    return {
      loadingStatus: true,
      columns: [
        {
          title: '名称',
          value: 'name',
        },
        {
          title: '持续时间',
          value: 'duration_alias'
        },
        {
          title: '原价',
          value: 'price',
        },
        {
          title: '优惠价',
          value: 'reduction_price',
        },
        {
          title: '实际价格',
          value: 'actual_price',
        },
        {
          title: '创建时间',
          value: 'created_at'
        },
      ],
      // 表格列表数据
      questionBankList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0, // 分页的offset,序号列使用
      },
      // 表单结构
      formData: {
        name: "",
        reduction_price: '',
        price: '',
        actual_price: '',
        duration: "",
        status: "0",
      },
      // 表单验证
      rules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        duration: [
          {required: true, message: '请输入持续时间', trigger: 'blur'}
        ],
        price: [
          {required: true, message: '请输入价格', trigger: 'blur'}
        ],
        reduction_price: [
          {required: true, message: '请输入优惠价格', trigger: 'blur'}
        ],
        status: [
          {required: true, message: '请选择题库状态', trigger: 'blur'}
        ]
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '560px',
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      //搜索表单
      searchForm: {},

      //自动填充数据
      restaurants: [],

      dataType: 1,
      isShowSelect: true,

      //下拉框搜索
      selectForm: {
        label: '状态',
        placeholder: '请选择状态',
      },

      typeList: [
        {
          id: "0",
          text: "启用"
        },
        {
          id: "-1",
          text: "禁用"
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    // 切换页码操作
    async switchPaging() {
      this.resetPackages()
    },

    // 响应添加按钮
    async addButton() {
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '添加套餐';
      // this.resetAddFormData();
      this.$nextTick(() => {
        this.drawerData.loading = false
      })
    },

    // 响应编辑按钮
    async editButton(id) {
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '编辑套餐(ID：' + id + ')';
      let {info} = await this.$api.getPackageListInfo({id});
      this.formData = info;
      let duration = info.duration;

      if (duration.indexOf("month") > -1) {
        this.dataType = 2;
        this.formData.duration = duration.substring(0, duration.indexOf("month"));

      } else if (duration.indexOf("year") > -1) {
        this.dataType = 3;
        this.formData.duration = duration.substring(0, duration.indexOf("year"));
      } else if (duration.indexOf("day") > -1) {
        this.dataType = 1;
        this.formData.duration = duration.substring(0, duration.indexOf("day"));
      }

      this.drawerData.loading = false
    },

    // 处理抽屉关闭
    drawerClose() {
      this.resetFormData();
      this.drawerData.visible = false;
      this.drawerData.loading = true
    },

    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['questionBankForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },

    // form数据提交，请求接口
    async formSubmit() {
      if (!this.formData.actual_price) {
        this.$message.error('实际付费价格不能为空');
        return;
      }

      this.drawerData.loading_text = '玩命提交中……';
      this.drawerData.loading = true;

      let formData = {};
      for (let key in this.formData) {
        if (this.formData.hasOwnProperty(key)) {
          formData[key] = this.formData[key];
        }
      }

      switch (this.dataType) {
        case DATE_CODE:
          formData.duration = this.formData.duration + "day";
          break;
        case MOUNTH_CODE:
          formData.duration = this.formData.duration + "month";
          break;
        case YEAR_CODE:
          formData.duration = this.formData.duration + "year";
          break;
      }


      let id = await this.$api.storePackage(formData);
      this.$nextTick(() => {
        this.drawerData.visible = false
      });
      this.$nextTick(() => {
        if (id) {
          this.resetPackages()
        }
      });
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      });
      this.resetFormData()
    },

    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteQuestionBank(id);
      if (info == 'ok') {
        this.$nextTick(() => {
          this.resetPackages('del')
        })
      } else {
        this.$message.error(info)
      }
    },

    // 初始化数据
    resetFormData() {
      // 初始化form表单
      this.formData = {
        name: "",
        reduction_price: '',
        price: '',
        actual_price: '',
        duration: "",
        status: "0",
      };

      this.$nextTick(() => {
        this.$refs['questionBankForm'].resetFields();
      })
    },

    // 初始化题库列表
    async resetPackages(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in params) {
        inputData[param] = params[param];
      }
      let {list, pages} = await this.$api.getPackageList(inputData);
      this.questionBankList = [];
      this.$nextTick(() => {
        this.questionBankList = list
      });
      this.pagingData.total = pages.total;
      this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 拖拽排序数据提交，请求接口
    async dragSortSubmit(ids) {
      let data = {
        ids: ids
      };
      let info = await this.$api.orderPackageList(data);
      if (info == 'ok') {
        this.$nextTick(() => {
          this.resetPackages()
        })
      }
      this.$message.success('保存成功!')
    },

    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      this.sortable = Sortable.create(el, {
        handle: ".drag-handle",
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: () => {
          let Ids = [];
          let tmp = el.querySelectorAll('.drag-handle');
          for (let i = 0, len = tmp.length; i < len; i++) {
            Ids.push(tmp[i].dataset.id)
          }
          this.dragSortSubmit(Ids)
        },
      })
    },

    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      this.resetPackages(DEL, this.searchForm);
    },


    //单选Radio发生变化
    radioChange(value) {
      let price = parseInt(this.formData.duration);

      this.limitInputDuration(price);
    },

    //限制持续时间
    limitInputDuration(price) {
      switch (this.dataType) {
        case DATE_CODE:
          if (price > 31) {
            this.formData.duration = 31 + "";
            this.$message("输入天数不能超过31");
          }
          break;
        case MOUNTH_CODE:
          if (price > 12) {
            this.formData.duration = 12 + "";
            this.$message("输入月份不能超过12");
          }
          break;
      }
    },

    //搜索重置
    onResetSearch() {
      this.searchForm = {}
      this.resetPackages();
    }
  },
  mounted() {
    this.resetPackages();
    this.$nextTick(() => {
      this.dragSort()
    })
  },

  watch: {
    'formData': {
      handler(newVal) {
        if (newVal.price && newVal.reduction_price) {
          let price = parseInt(newVal.price);
          let reductionPrice = parseInt(newVal.reduction_price);

          if (price < reductionPrice) {
            this.formData.actual_price = '';
            this.$message("优惠价格不能高于原价");
            this.formData.actual_price = '';
            this.formData.reduction_price = '';
            return;
          }

          let actualPrice = parseInt(newVal.price) - parseInt(newVal.reduction_price) + "";
          this.formData.actual_price = actualPrice ? actualPrice : '0';
        }
      }, deep: true
    },

    'formData.duration'(newVal) {
      let price = parseInt(newVal);
      if (price === 0) {
        this.$message("时间不能为0");
        this.formData.duration = "";
        return;
      }
      this.limitInputDuration(price);
      // switch (this.dataType) {
      //     case DATE_CODE:
      //         if (price > 31) {
      //             this.formData.duration = 31 + "";
      //             this.$message("输入天数不能超过31");
      //         }
      //         break;
      //     case MOUNTH_CODE:
      //         if (price > 12) {
      //             this.formData.duration = 12 + "";
      //             this.$message("输入月份不能超过12");
      //         }
      //         break;
      // }
    }
  }
}
</script>

<style lang="stylus" scoped>
.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0px
  margin-right 12px

/*.el-select > .el-input*/
/*width 373.33px*/

.el-radio.is-bordered
  width 100px

.el-color-picker
  position absolute

.color-green
  color #67c23a

.color-red
  color #f56c6c
</style>
